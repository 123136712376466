<template>
  <section id="dashboard-main" class="mt-2">
    <b-row class="match-height">
      <!-- 장애 현황 파악 리스트 -->
      <b-col xl="12" lg="12" md="12" class="ecs-card">
        <!-- Table Container Card -->
        <b-card no-body>
          <div class="mr-1 ml-1 mt-1 mb-0">
            <b-row>
              <!-- 타이틀 -->
              <b-col cols="12" xl="12" lg="12" md="12" class="clearfix">
                <h4 class="float-left font-weight-bolder mt-8px">월별통계</h4>
              </b-col>
              <b-col cols="12" xl="12" lg="12" md="12" class="clearfix">
                <div
                  class="justify-content-center spinner-custom float-right ml-1"
                  :class="{ 'd-none': active }"
                >
                  <b-spinner
                    v-for="variant in variants"
                    :variant="variant"
                    :key="variant"
                  ></b-spinner>
                </div>
                <!--<b-form-select
                    class="col-md-2 float-right"
                    v-model="selectArea"
                    size=""
                    :options="this.areaOption"
                    @change="setFilter(selectArea)"
                    placeholder="UCS List"
                  />
                  -->

                <input
                  v-model="ip"
                  class="form-control col-md-2 float-right mr-2"
                  placeholder="ip 검색"
                />
                <input
                  v-model="area"
                  class="form-control col-md-2 float-right mr-2"
                  placeholder="권역 검색"
                />
                <flat-pickr
                  v-model="tmEnd"
                  class="form-control col-md-2 float-right mr-2"
                  :config="config"
                />
                <span class="display-inline-block float-right px-1">~</span>
                <flat-pickr
                  v-model="tm"
                  class="form-control col-md-2 float-right"
                  :config="config"
                />
              </b-col>
              <b-col cols="12" xl="12" lg="12" md="12" class="clearfix">
                <b-button
                  v-on:click="search"
                  class="form-control col-md-2 float-right mr-2"
                  >검색</b-button
                >
                <b-button
                  v-on:click="reset"
                  class="form-control col-md-2 float-right mr-2"
                  >검색 초기화</b-button
                >
                <b-button
                  class="form-control col-md-2 float-right mr-2"
                  @click="makeExcelFile"
                  >엑셀 다운</b-button
                >
              </b-col>
              <!-- 타이틀 END -->
              <!-- 검색 박스 / 버튼 -->
            </b-row>
          </div>
          <div class="pl-1 pr-1">
            <vue-good-table
              :columns="ServObNtColumns"
              :rows="this.resultList"
              :rtl="direction"
              v-dragscroll="{ target: '.vgt-responsive' }"
              :search-options="{
                enabled: false,
                externalQuery: searchTerm,
              }"
              :pagination-options="{
                enabled: true,
                perPage: pageLength,
              }"
              theme="my-theme"
              class="table good-table-custom table-light"
            >
              <div slot="emptystate" class="text-center">
                <span v-if="active === true">조회된 목록이 없습니다.</span>
                <span :class="{ 'd-none': active }">
                  <b-skeleton animation="wave" width="100%"></b-skeleton>
                  <b-skeleton animation="wave" width="85%"></b-skeleton>
                  <b-skeleton animation="wave" width="60%"></b-skeleton>
                  <b-skeleton animation="wave" width="93%"></b-skeleton>
                  <b-skeleton animation="wave" width="45%"></b-skeleton>
                </span>
              </div>
              <template slot="table-row" slot-scope="props">
                <!-- Column: Name -->
                <div v-if="props.column.field === 'sn'" class="text-nowrap">
                  {{ props.row.originalIndex + 1 }}
                </div>
                <div v-if="props.column.field === 'name'" class="text-nowrap">
                  {{ props.formattedRow.name }}
                </div>
                <div v-if="props.column.field === 'ip'" class="text-nowrap">
                  {{ props.formattedRow.ip }}
                </div>
                <div
                  v-if="props.column.field === 'severity'"
                  class="text-nowrap"
                >
                  {{ props.formattedRow.severity }}
                </div>
                <div
                  v-if="props.column.field === 'affected'"
                  class="text-nowrap"
                >
                  <span v-if="props.formattedRow.affected">{{
                    props.formattedRow.affected
                  }}</span>
                  <span v-else>{{ props.row.dn }}</span>
                </div>
                <div v-if="props.column.field === 'code'" class="text-nowrap">
                  {{ props.formattedRow.code }}
                </div>
                <div v-if="props.column.field === 'id'" class="text-nowrap">
                  {{ props.formattedRow.id }}
                </div>
                <div v-if="props.column.field === 'cause'" class="text-nowrap">
                  {{ props.formattedRow.cause }}
                </div>

                <div v-if="props.column.field === 'user'" class="text-nowrap">
                  {{ props.formattedRow.user }}
                </div>
                <div v-if="props.column.field === 'descr'" class="text-nowrap">
                  {{ props.formattedRow.descr }}
                </div>
                <div
                  v-if="props.column.field === 'created'"
                  class="text-nowrap"
                >
                  {{ props.formattedRow.created }}
                </div>
                <div v-if="props.column.field === 'currTm'" class="text-nowrap">
                  {{ props.formattedRow.currTm }}
                </div>
                <!-- <div
                    v-if="props.column.field === 'setting'"
                    class="text-nowrap"
                    >
                    <a
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      v-b-toggle.serv-log-detail
                      size="sm"
                      @click="detailProps(items[props.row.originalIndex])"
                      >
                      <button
                      style="border: 1px solid #eee; border-radius: 10px; padding: 8px; font-size : 10px;"
                      size="sm"
                      >
                      상세
                      </button>
                    </a>
                  </div> -->
              </template>
              <template slot="pagination-bottom" slot-scope="props">
                <div class="d-flex justify-content-end flex-wrap">
                  <div>
                    <b-pagination
                      :value="1"
                      :total-rows="props.total"
                      :per-page="pageLength"
                      first-number
                      last-number
                      align="right"
                      prev-class="prev-item"
                      next-class="next-item"
                      class="m-2"
                      @input="
                        (value) => props.pageChanged({ currentPage: value })
                      "
                    >
                      <template #prev-text>
                        <feather-icon icon="ChevronLeftIcon" size="18" />
                      </template>
                      <template #next-text>
                        <feather-icon icon="ChevronRightIcon" size="18" />
                      </template>
                    </b-pagination>
                  </div>
                </div>
              </template>
            </vue-good-table>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>
<style>
.badge-critical {
  background-color: #ea5455 !important;
}
.badge-major {
  background-color: #ff9f43 !important;
}
.badge-minor {
  background-color: #ffd500 !important;
}
.badge-warning {
  background-color: #00967e !important;
}
.badge-info {
  background-color: #616161 !important;
}
</style>
<script>
// import ucsData from '@/assets/api/ucs.ts'
import Ripple from "vue-ripple-directive";

import {
  BCard,
  BRow,
  BCol,
  BTable,
  BFormSelect,
  BButton,
  VBToggle,
  BSpinner,
  BSkeleton,
  BPagination,
  BTabs,
  BTab,
  BBadge,
  BFormInput,
  BFormDatepicker,
} from "bootstrap-vue";

import store from "@/store/index";

import { dragscroll } from "vue-dragscroll";

import { VueGoodTable } from "vue-good-table";

import flatPickr from "vue-flatpickr-component";
import { Korean } from "flatpickr/dist/l10n/ko.js";

// import * as xlsx from 'xlsx';

const qs = require("qs");

export default {
  directives: {
    Ripple,
    dragscroll,
    "b-toggle": VBToggle,
  },
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BSpinner,
    BTable,
    // BAvatar,
    BSkeleton,
    VueGoodTable,
    BFormSelect,
    BButton,
    BPagination,
    BTabs,
    BTab,
    BBadge,
    flatPickr,
    BFormDatepicker,
  },
  data() {
    return {
      ucsServerUrl: "/ucsmaster/selectUcsMasterListAllJson",
      ucsUrl: "ucsapi/nuova",
      ip: "",
      area: "",
      date: "2020-10-16",
      config: {
        enableTime: true,
        allowInput: false,
        allowInvalidPreload: false,
        //dateFormat: 'Y-m-d',
        locale: Korean,
        maxDate: this.setTime(0),
        allowInput: true,
        altInput: true,
        dateFormat: "Y-m",
        altFormat: "Y-m",
        ariaDateFormat: "Y-m",
      },
      data: {
        items: [],
        totalInvoices: 0,
      },
      pageLength: 10,
      searchTerm: "",
      checkType: "all",
      type: "all",
      variants: ["primary"],
      active: true,
      perPage: 10,
      currentPage: 1,
      perPageOptions: [10, 25, 50, 100],
      itemLength: 0,
      selectArea: 0,
      areaOption: [{ text: "전체 서버", value: 0 }],
      ServObNtColumns: [
        {
          field: "sn",
          label: "번호",
          sortable: true,
          sortFn: this.sortFn,
          width: "60px",
        },
        {
          field: "name",
          label: "이름",
          sortable: true,
          sortFn: this.sortFn,
          filterOptions: {
            enabled: true,
            placeholder: "이름",
          },
        },
        {
          field: "ip",
          label: "IP",
          sortable: true,
          sortFn: this.sortFn,
          filterOptions: {
            enabled: true,
            placeholder: "IP",
          },
        },
        {
          field: "id",
          label: "권역",
          sortable: true,
          sortFn: this.sortFn,
          filterOptions: {
            enabled: true,
            placeholder: "권역",
          },
        },
        {
          field: "id",
          label: "cpu 평균",
          sortable: true,
          sortFn: this.sortFn,
          filterOptions: {
            enabled: true,
            placeholder: "cpu 평균",
          },
        },
        {
          field: "severity",
          label: "cpu 최대",
          sortable: true,
          sortFn: this.sortFn,
          filterOptions: {
            enabled: true,
            placeholder: "cpu 최대",
          },
        },
        {
          field: "affected",
          label: "메모리 평균(%)",
          sortable: true,
          sortFn: this.sortFn,
          filterOptions: {
            enabled: true,
            placeholder: "메모리 평균(%)",
          },
        },
        {
          field: "code",
          label: "메모리 평균(GB)",
          sortable: true,
          sortFn: this.sortFn,
          filterOptions: {
            enabled: true,
            placeholder: "메모리 평균(GB)",
          },
        },
        {
          field: "cause",
          label: "메모리 최대(%)",
          sortable: true,
          sortFn: this.sortFn,
          filterOptions: {
            enabled: true,
            placeholder: "메모리 최대(%)",
          },
        },
        {
          field: "user",
          label: "메모리 최대(GB)",
          sortable: true,
          sortFn: this.sortFn,
          filterOptions: {
            enabled: true,
            placeholder: "메모리 최대(GB)",
          },
        },

        {
          field: "created",
          label: "발생시점",
          sortable: true,
          sortFn: this.sortFn,
        },
        {
          field: "currTm",
          label: "등록시점",
          sortable: true,
          sortFn: this.sortFn,
        },
      ],
      items: [],
      resultList: [],
      totalList: [],
      // 체크박스 변수
      checkItems: [],
      checkItem: [],
      payload: [],
      infoItem: [],
      criticalItem: [],
      majorItem: [],
      minorItem: [],
      warningItem: [],
      clearedItem: [],
      detailItem: {},
      // detailItemObj: {},
      tm: this.setTime(1),
      tmSet: this.setTime(1),
      tmEnd: this.setTime(0),
      tmEndSet: this.setTime(0),
      severitySearchString: [
        // {xmlGb: 'UCSGBS0001'},
        { xmlGb: "UCSGBS0002" },
        { xmlGb: "UCSGBS0003" },
        { xmlGb: "UCSGBS0004" },
        { xmlGb: "UCSGBS0005" },
        { xmlGb: "UCSGBS0006" },
      ],
      tabType: "all",
      checkActive: false,
      unSeverityList: [],
      copyObj: [],
      sum: 0,
    };
  },
  watch: {
    /**
     * 시간별 data filtering (starttime)
     * @create 2021-04-15
     * @author 정한슬
     * 시간 선택 시 해당 조건에 맞는 data filtering
     */
    tm: function (val) {
      // 시작기간의 변동이 없을 시 아무 동작 안함
      if (this.tmSet === this.tm) {
      } else {
        // 시작기간의 변동이 있을 시 DB 조회
        this.setTm();
        //this.getServLog()
      }
      // 1시간 기준으로 filter 조회
      // let sdata = new Array()
      // sdata = this.copyObj.filter( (x) => {
      //   if(x.currTm) return x.currTm >= this.tm && this.tmEnd >= x.currTm
      //   else if(x.tm) return x.tm >= this.tm && this.tmEnd >= x.tm
      // })

      // // Table에 바인딩시키기 위한 변수
      // this.resultList = sdata
      // this.resultList.sort(function (a,b){
      //   return b.id - a.id
      // })

      // // resultList를 초기화시키기 위한 변수
      // this.items = sdata
      // this.items.sort(function (a,b){
      //   return b.id - a.id
      // })
    },
    /**
     * 시간별 data filtering (endtime)
     * @create 2021-04-15
     * @author 정한슬
     * 시간 선택 시 해당 조건에 맞는 data filtering
     */
    tmEnd: function (val) {
      // 종료기간이 시작기간보다 빠를 시.
      if (this.tm > this.tmEnd) {
        // this.tm = this.setTime(1)
        this.tmEnd = this.setTime(0);
        alert("종료기간이 시작기간보다 빠릅니다.");
        return false;
      } else if (this.tmEndSet === this.tmEnd) {
        // 종료기간의 변동이 있을 시 DB 조회
      } else {
        // 종료기간의 변동이 있을 시 DB 조회
        this.setTm();
        //this.getServLog()
      }
      // if (this.tm > this.tmEnd) {
      //   // this.tm = this.setTime(1)
      //   this.tmEnd = this.setTime(0)
      //   alert("종료기간이 시작기간보다 빠릅니다.")
      //   return false
      // } else {
      //   let edata = new Array()
      //   edata = this.copyObj.filter( (x) => {
      //     if(x.currTm) return x.currTm >= this.tm && this.tmEnd >= x.currTm
      //     else if(x.tm) return x.tm >= this.tm && this.tmEnd >= x.tm
      //   })

      //   // Table에 바인딩시키기 위한 변수
      //   this.resultList = edata
      //   this.resultList.sort(function (a,b){
      //     return b.id - a.id
      //   })

      //   // resultList를 초기화시키기 위한 변수
      //   this.items = edata
      //   this.items.sort(function (a,b){
      //     return b.id - a.id
      //   })
      // }
    },
  },
  created() {
    this.tm = this.setTime(1);
    this.tmEnd = this.setTime(0);
    // this.getServLog()
  },
  mounted() {
    this.getServer(); // 첫 페이지 로드 시 서버 리스트 조회
    // this.getServAll() // 첫 페이지 로드 시 상세 정보 조회
    //this.cAll() // 첫 페이지 로드 시 체크박스 전체 체크
    this.getServLog(); // 첫 페이지 로드 시 DB 전체 조회
    this.setTm();
  },
  methods: {
    /**
     * 검색 버튼
     * @created 2022-02-22
     * @author 김은호
     * @description 아이피, 권역, 날짜입력 바탕으로 cpu 메모리 조회
     */
    search() {
      console.log("ip", this.ip);
      console.log("area", this.area);
      console.log("tm", this.tmSet);
      console.log("tm end", this.tmEndSet);
      this.getServLog();
    },
    /**
     * 검색 초기화 버튼
     * @created 2022-02-22
     * @author 김은호
     * @description 검색 텍스트 입력, 날짜 입력 초기화
     */
    reset() {
      this.ip = "";
      this.area = "";
      this.tm = this.setTime(1);
      this.tmEnd = this.setTime(0);
      //console.log("reset");
    },
    /**
     * 엑셀 파일 다운로드
     * @created 2022-02-22
     * @author 김은호
     * @description 조회된 서버 데이터를 엑셀로 저장
     */
    makeExcelFile() {
      const workBook = xlsx.utils.book_new();
      //현재 더미데이터, 추후 this.data1 부분에 현재 조회 대상 데이터 저장 필요
      // 2022-02-24 현재 조회되고 있는 데이터로 다운받게 수정
      const workSheet = xlsx.utils.json_to_sheet(this.data2);
      xlsx.utils.book_append_sheet(workBook, workSheet, "example");
      xlsx.writeFile(workBook, "example.xlsx");

      console.log("excel download", this.data2);
      /* let tab_text = '<html xmlns:x="urn:schemas-microsoft-com:office:excel">'
      tab_text += '<head><meta http-equiv="content-type" content="application/vnd.ms-excel; charset=UTF-8">'
      tab_text += '<xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet>'
      tab_text += '<x:Name>Test Sheet</x:Name>'
      tab_text += '<x:WorksheetOptions><x:Panes></x:Panes></x:WorksheetOptions></x:ExcelWorksheet>'
      tab_text += '</x:ExcelWorksheets></x:ExcelWorkbook></xml></head><body>'
      tab_text += "<table>"
      const temp = document.getElementById('table').innerHTML
      // console.log(temp)
      tab_text += temp
      tab_text += '</table></body></html>'
      console.log(tab_text)
      const fileName = 'exampleTable.xls'
      const a_tag = document.createElement('a')
      const blob = new Blob([tab_text], { type: 'application/vnd.ms-excel;charset=utf-8;' })
      a_tag.href = window.URL.createObjectURL(blob)
      a_tag.download = fileName
      a_tag.click()
      */
    },
    /**
     * UCS 서버 리스트 조회
     * @created 2021-05-20
     * @author 박재헌
     * @description UCS 서버 리스트 조회 후 셀렉트 검색 기능 활성화
     */
    getServer() {
      this.$jBack({
        method: "post",
        url: this.ucsServerUrl,
      }).then((response) => {
        const serverList = response.data.resultList;
        this.areaOption = [{ value: 0, text: "전체 서버" }];
        for (let index = 0; index < serverList.length; index++) {
          const element = serverList[index];
          this.areaOption.push({ value: element.sn, text: element.name });
        }
      });
    },
    /**
     * Tm 검색 후 watch 감지용 tmSet / tmEndSet
     * @created 2021-05-20
     * @author 박재헌
     * @description 각 Tm 및 TmEnd 값 변동 없을 시 DB 검색 안되도록하기 위한 세팅
     */
    setTm() {
      this.tmSet = this.tm;
      this.tmEndSet = this.tmEnd;
      // console.log(this.tmSet, this.tm)
    },
    /**
     * 탭버튼 조회 clickTab()
     * @create 2021-04-13
     * @author 박재헌
     * 각 탭 버튼 클릭 시 해당 조건에 맞는 장애현황 조회
     */
    clickTab(severity) {
      const checkbox = document.getElementById("allCheck");
      // active된 탭을 한번 더 클릭 했을 때 필터를 또 실행시키지않도록 분기처리
      if (this.tabType != severity) {
        // tabType이 인자와 다를 시 tabType 변경
        this.tabType = severity;
        // Table 바인딩 변수 초기화
        this.resultList = this.items;
        // 전체 All 탭 클릭 시 전체 리스트 볼 수 있도록 분기처리
        if (severity === "all") {
          this.checkActive = false;
          // all 체크박스 true
          checkbox.checked = true;
          this.cAll();
          // unSeverityList 초기화
          this.unSeverityList = [];
        } else {
          this.checkActive = true;
          // All 탭이 아닐 시 해당 탭에 맞게 resultList 필터
          this.resultList = this.resultList.filter((x) => {
            return x.severity === severity;
          });
        }
      } else {
      }
    },
    /**
     * 이벤트 로그 현황
     * @create 2021-04-13
     * @author 박재헌
     * @returns {json}
     * @description 이벤트 로그 현황
     */
    getServLog() {
      this.resultList = [];
      this.items = [];
      this.active = false;
      let param = {
        tm: this.tm,
        tmEnd: this.tmEnd,
        tbUcsMasterSn: this.selectArea,
        severitySearchString: this.severitySearchString,
      };
      param.severitySearchString = param.severitySearchString
        ? JSON.stringify(param.severitySearchString)
        : [];
      this.$jBack({
        method: "post",
        url: "/ucsxmlmaster/selectUcsXmlMasterFaultList",
        data: qs.stringify(param),
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
        },
      })
        .then((response) => {
          // console.log(response)
          let items = response.data.resultList;
          // items.reverse()
          // console.log(items)
          // console.log("succues 7")
          let itemsJSON = new Array();
          for (let index = 0; index < items.length; index++) {
            // console.log(items[index])
            const element = items[index];
            itemsJSON.push(JSON.parse(element.plainXml));
            // console.log(itemsJSON)
          }
          // console.log("succues 6")
          // obj 복사
          // this.copyObj = this.deepCopy(itemsJSON)
          // console.log("succues 5")
          // 1시간 기준으로 filter 조회
          itemsJSON = itemsJSON.filter((x) => {
            if (x.currTm) return x.currTm >= this.tm && this.tmEnd >= x.currTm;
            else if (x.tm) return x.tm >= this.tm && this.tmEnd >= x.tm;
          });

          //입력한 ip가 있으면 ip filter 조회(검색)
          if (this.ip !== "") {
            itemsJSON = itemsJSON.filter((x) => {
              return x.ip == this.ip;
            });
          }
          //권역 검색 추후 추가 필요
          // console.log("succues 4")

          // 중복 제거
          // let payload = itemsJSON
          // itemsJSON = this.getUniqueObjectArray(payload, 'id', 'sn')
          // console.log(itemsJSON)

          // Table에 바인딩시키기 위한 변수
          this.resultList = itemsJSON;
          this.resultList.sort(function (a, b) {
            return b.id - a.id;
          });
          // console.log("succues 3")

          // resultList를 초기화시키기 위한 변수
          this.items = itemsJSON;

          this.items.sort(function (a, b) {
            return b.id - a.id;
          });
          // console.log("succues 1")
          // 체크박스 체크해제 후 날짜 재 조회 시 해당 필터로 조회
          if (
            this.unSeverityList != [] ||
            this.unSeverityList != undefined ||
            this.unSeverityList != null
          ) {
            for (let index = 0; index < this.unSeverityList.length; index++) {
              const element = this.unSeverityList[index];
              this.resultList = this.resultList.filter((x) => {
                return x.severity !== element;
              });
            }
          }
          // console.log("succues 2")
          // console.log(this.resultList)
          // console.log(this.resultList)

          // loading
          this.active = true;

          // pagenation
          // this.totalPageCount = response.data.paginationInfo.totalPageCount
          // this.totalRecordCount = response.data.paginationInfo.totalRecordCount
        })
        .catch((error) => {
          this.active = true;
        });
    },
    setFilter(data) {
      // this.$set(this.ServObNtColumns[2].filterOptions, 'filterValue', data);
      this.getServLog();
    },
    getUniqueObjectArray(array, key) {
      return array.filter((item, i) => {
        return (
          array.findIndex((item2, k) => {
            return item[key] === item2[key];
          }) === i
        );
      });
    },
    /**
     * 체크박스 이벤트 cAll()
     * @author 박재헌
     * @create 2021-04-13
     * 첫 페이지 또는 탭간 이동 시 전체 체크박스 true 및 resultList 조회
     */
    cAll(type) {
      this.checkType = type;
      // All 체크박스 지역변수
      const checkbox = document.getElementById("allCheck");
      // 전체 체크박스 리스트 지역변수
      const checkboxType = document.getElementsByTagName("input");
      // All 체크박스를 제외한 타입별 체크박스 리스트 지역변수
      const checkList = [];
      for (let index = 0; index < checkboxType.length; index++) {
        const element = checkboxType[index];
        // All 체크박스를 제외한 타입별 체크박스 리스트 지역변수 PUSH
        if (element.type === "checkbox" && element.id != "allCheck") {
          checkList.push(element);
        }
      }

      if (checkbox.checked === true) {
        // All 체크박스 true 시 전체 체크박스 true
        for (let index = 0; index < checkList.length; index++) {
          const element = checkList[index];
          element.checked = true;
        }

        // 장애현황조회 전체 조회
        this.unSeverityList = [];
        this.resultList = this.items;
      } else {
        // All 체크박스 true 일 때 클릭시 전체 체크박스 해제
        for (let index = 0; index < checkList.length; index++) {
          const element = checkList[index];
          if (element.type === "checkbox" && element.id != "allCheck") {
            element.checked = false;
          }
        }
        // 장애현황조회 초기화
        this.unSeverityList = [
          "critical",
          "major",
          "minor",
          "warning",
          "cleared",
        ];
        this.resultList = [];
      }
      // this.getServLog()
    },
    /**
     * 체크박스 타입별 이벤트 cPart()
     * @create 2021-04-13
     * @author 박재헌
     * 체크박스별 장애현황 조회
     */
    cPart(type) {
      this.checkType = type;
      let checkbox = "";
      let checkAll = document.getElementById("allCheck");
      // 전체 리스트에서 unSeverityList에 들어있는 정보를 필터하기위해 리스트 초기화
      this.resultList = this.items;

      // 해당 체크박스의 타입 체크 후 checkbox 지역변수에 담기
      if (type === "all") {
      } else if (type === "info") {
        checkbox = document.getElementById("infoCheck");
      } else if (type === "critical") {
        checkbox = document.getElementById("criticalCheck");
      } else if (type === "major") {
        checkbox = document.getElementById("majorCheck");
      } else if (type === "minor") {
        checkbox = document.getElementById("minorCheck");
      } else if (type === "warning") {
        checkbox = document.getElementById("warningCheck");
      } else if (type === "cleared") {
        checkbox = document.getElementById("clearedCheck");
      }

      if (checkbox.checked === true) {
        // 해당 체크박스 해제 시 unSeverityList 제거
        if (checkbox === document.getElementById("infoCheck")) {
          this.unSeverityList = this.unSeverityList.filter((x) => {
            return x != "info";
          });
        } else if (checkbox === document.getElementById("criticalCheck")) {
          this.unSeverityList = this.unSeverityList.filter((x) => {
            return x != "critical";
          });
        } else if (checkbox === document.getElementById("majorCheck")) {
          this.unSeverityList = this.unSeverityList.filter((x) => {
            return x != "major";
          });
        } else if (checkbox === document.getElementById("minorCheck")) {
          this.unSeverityList = this.unSeverityList.filter((x) => {
            return x != "minor";
          });
        } else if (checkbox === document.getElementById("warningCheck")) {
          this.unSeverityList = this.unSeverityList.filter((x) => {
            return x != "warning";
          });
        } else if (checkbox === document.getElementById("clearedCheck")) {
          this.unSeverityList = this.unSeverityList.filter((x) => {
            return x != "cleared";
          });
        } else {
        }
        this.cPartAll();
      } else {
        checkAll.checked = false;
        // 해당 체크박스 해제 시 unSeverityList 추가
        if (checkbox === document.getElementById("infoCheck")) {
          this.unSeverityList.push("info");
        } else if (checkbox === document.getElementById("criticalCheck")) {
          this.unSeverityList.push("critical");
        } else if (checkbox === document.getElementById("majorCheck")) {
          this.unSeverityList.push("major");
        } else if (checkbox === document.getElementById("minorCheck")) {
          this.unSeverityList.push("minor");
        } else if (checkbox === document.getElementById("warningCheck")) {
          this.unSeverityList.push("warning");
        } else if (checkbox === document.getElementById("clearedCheck")) {
          this.unSeverityList.push("cleared");
        } else {
        }
      }
      // console.log(this.unSeverityList)
      // console.log(this.resultList)
      // 체크박스 체크해제 false 시 unSeverityList에 해당 severity를 담은 후 !== filter로 필터
      if (
        this.unSeverityList != [] ||
        this.unSeverityList != undefined ||
        this.unSeverityList != null
      ) {
        for (let index = 0; index < this.unSeverityList.length; index++) {
          const element = this.unSeverityList[index];
          this.resultList = this.resultList.filter((x) => {
            return x.severity !== element;
          });
        }
      }
    },
    /**
     * 체크박스 전체 체크 이벤트 cPartAll()
     * @create 2021-04-13
     * @author 박재헌
     * 체크박스 전체 체크될 시 All 체크박스 체크
     */
    cPartAll() {
      let count = 0;
      // 전체 체크박스 리스트 지역변수
      const checkboxType = document.getElementsByTagName("input");
      // All 체크박스를 제외한 체크박스 리스트 지역변수
      const checkList = [];
      for (let index = 0; index < checkboxType.length; index++) {
        const element = checkboxType[index];
        // All 체크박스를 제외한 체크박스 리스트 지역변수 PUSH
        if (element.type === "checkbox" && element.id != "allCheck") {
          checkList.push(element);
        }
      }
      // All 체크박스
      let checkAll = document.getElementById("allCheck");
      // 체크박스 전체 갯수 true 체크 후 count
      for (let index = 0; index < checkList.length; index++) {
        const element = checkList[index];
        if (element.checked == true) {
          count++;
        }
      }
      // All 체크박스를 제외한 나머지 체크박스가 전체 true 일 시 All 체크박스 true
      if (count == checkList.length) {
        checkAll.checked = true;
      }
    },
    deepCopy(obj) {
      var copy;

      // Handle the 3 simple types, and null or undefined
      if (null == obj || "object" != typeof obj) return obj;

      // Handle Date
      if (obj instanceof Date) {
        copy = new Date();
        copy.setTime(obj.getTime());
        return copy;
      }

      // Handle Array
      if (obj instanceof Array) {
        copy = [];
        for (var i = 0, len = obj.length; i < len; i++) {
          copy[i] = this.deepCopy(obj[i]);
        }
        return copy;
      }

      // Handle Object
      if (obj instanceof Object) {
        copy = {};
        for (var attr in obj) {
          if (obj.hasOwnProperty(attr)) copy[attr] = this.deepCopy(obj[attr]);
        }
        return copy;
      }
      throw new Error("Unable to copy obj! Its type isn't supported.");
    },
    /**
     * 페이지 로드 시 시간 갱신
     * @create 2021-04-13
     * @author 박재헌
     * 시간 갱신 setTime
     */
    setTime(h) {
      const time = new Date();
      const year = time.getFullYear();
      const month = time.getMonth() + 1;
      const day = time.getDate();
      let hour = time.getHours();
      if (time.getHours() == 0 && h == 1) {
        hour = 23;
      } else {
        hour = time.getHours() - h;
      }
      const minutes = time.getMinutes();
      const seconds = time.getSeconds();
      let s = "";
      if (hour < 24) {
        // s = `${year<10 ? `0${year}`:year}-${month<10 ? `0${month}`:month}-${day<10 ? `0${day}`:day} ${hour<10 ? `0${hour}`:hour}:${minutes<10 ? `0${minutes}`:minutes}:${seconds<10 ? `0${seconds}`:seconds}`
        s = `${year < 10 ? `0${year}` : year}-${
          month < 10 ? `0${month}` : month
        }-${day < 10 ? `0${day}` : day} ${hour < 10 ? `0${hour}` : hour}:${
          minutes < 10 ? `0${minutes}` : minutes
        }`;
      } else {
        const times = new Date();
        const years = times.getFullYear();
        const months = times.getMonth() + 1;
        const days = times.getDate();
        const hours = times.getHours();
        const minutess = times.getMinutes();
        const secondss = times.getSeconds();
        // s = `${years<10 ? `0${years}`:years}-${months<10 ? `0${months}`:months}-${days<10 ? `0${days}`:days} ${hours<10 ? `0${hours}`:hours}:${minutess<10 ? `0${minutess}`:minutess}:${secondss<10 ? `0${secondss}`:secondss}`
        s = `${years < 10 ? `0${years}` : years}-${
          months < 10 ? `0${months}` : months
        }-${days < 10 ? `0${days}` : days} ${
          hours < 10 ? `0${hours}` : hours
        }:${minutess < 10 ? `0${minutess}` : minutess}`;
      }
      // console.log(s)
      return s;
    },
  },
  computed: {
    rows() {
      return this.items.length;
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true;
        return this.dir;
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false;
      return this.dir;
    },
  },
};
</script>

<style lang="scss">
th,
td {
  text-align: center;
  white-space: nowrap;
}
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-good-table.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
